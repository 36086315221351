import Rebase from 're-base';
import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({apiKey: "AIzaSyAzhplwtxzM-Z6m5awa-yqwTQtkb__BnMI", authDomain: "catch-of-the-day-2-106f5.firebaseapp.com", databaseURL: "https://catch-of-the-day-2-106f5.firebaseio.com"});

const base = Rebase.createClass(firebaseApp.database());

export {
  firebaseApp
};
export default base;
