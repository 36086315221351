import React from 'react';
import {formatPrice} from '../helpers';
import PropTypes from 'prop-types';

class Fish extends React.Component {
  static propTypes = {
    details: PropTypes.shape({name: PropTypes.string, image: PropTypes.string, desc: PropTypes.string, status: PropTypes.string, price: PropTypes.number}),
    addToOrder: PropTypes.func
  };
  render() {
    const {name, image, desc, status, price} = this.props.details;
    const isAvailable = status === 'available';
    return (<li className="menu-fish">
      <img src={image} alt={name}/>
      <h3 className="fish-name">
        {name}
        <span className="price">{formatPrice(price)}</span>
      </h3>
      <p>{desc}</p>
      <button onClick={() => this.props.addToOrder(this.props.index)} disabled={!isAvailable}>{
          isAvailable
            ? 'Add To Cart'
            : 'Sold Out!'
        }</button>
    </li>);

  }
}

export default Fish;
